<template>
  <div class="establish-college" id="establish_college">
    <div class="box">
      <!--      <div class="box-title">开设院校</div>-->
      <!--      <div class="box-content"></div>-->
      <condition-screen @screenParameters="screenParameters"></condition-screen>
    </div>

    <div class="total">共{{ mainData.Total }}条数据</div>

    <div class="main">
      <div class="item" v-for="(item, index) in showList" :key="index">
        <img class="logo" :src="item.LogoUrl" alt="">
        <div class="info">
          <div class="info-item name" @click="openCollege(item)">{{ item.SchoolName }}</div>
          <div class="info-item category">{{ item.CategoryNames }}</div>
          <div class="info-item tag">{{ item.ProvinceName + ' ' + item.TagNames }}</div>
        </div>
        <div class="rank">
          <div class="name">专业排名</div>
          <div class="value">{{ showRank(item ) }}</div>
        </div>
        <div class="assess">
          <div class="name">评级</div>
          <div class="value">{{ item.MajorRankLevel || ' -' }}</div>
        </div>
        <div class="hot">
          <div class="name">总分</div>
          <div class="value">{{ item.MajorRankScore || ' -' }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import {SpecialtySchoolList} from '@/api/search_specialty'
import API from '@/api/config'
import ConditionScreen from "./local-components/ConditionScreen";

export default {
  components: {
    ConditionScreen
  },
  name: "EstablishCollege",
  data () {
    return {
      pageFlag: true, // 分页防抖
      query: {
        SpecialtyCode: this.$route.query.SpecialtyCode,
        SchoolProvinceIdList: [],
        SchoolCategoryIdList: [],
        SchoolTagList: [],
        SchoolName: "",
        PageIndex: 1,
        PageSize: 10
      },
      mainData: {},
      showList: []
    }
  },
  props: {
    selectedIndex: {
      type: Number,
      default: 0
    }
  },
  mounted () {
    this.getList()

    // 添加滚动分页事件
    this.scrollPagination()

  },
  methods: {
    // 打开对应院校详情
    openCollege (item) {
      const routeUrl = this.$router.resolve({
        path: '/university-detail',
        query: {id: item.SchoolId}
      })
      window.open(routeUrl.href, '_blank')
    },
    // 分页
    scrollPagination () {

      window.addEventListener('scroll', () => {

        if (this.selectedIndex !== 3) return

        let clientHeight = document.documentElement.clientHeight || document.body.clientHeight
        let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight
        let scrollTop = document.documentElement.scrollTop || document.body.scrollTop

        if (scrollHeight - (clientHeight + scrollTop) < 300) { // 滚动还差300px 就触底了,这时触发分页

          if (this.query.PageIndex < this.mainData.TotalPage) { // 判断是否到最后一页了

            if (this.pageFlag) {
              this.pageFlag = false
              this.query.PageIndex++
              this.getList().then(() => {
                this.pageFlag = true
              })
            }
          }
        }
      })
    },
    // 点击筛选
    screenParameters (parameters, callback) {

      this.query = parameters // 这样赋值，两个对象就对应了同一个内存空间，连通了，必须PageIndex=1
      this.query.PageIndex = 1
      this.showList = []
      this.mainData = {}
      this.getList().then(() => {
        callback()
      })
    },

    // 获取院校列表数据
    getList () {
      return new Promise((resolve, reject) => {
        API.Specialty.SpecialtySchoolList(this.query).then(res => {
          this.mainData = res.data
          this.showList = this.showList.concat(res.data.Data)

          resolve()
        }).catch(err => {
          reject()
        })
      })
    },
    // 显示排名
    showRank (item) {
      if (item.SpecialtyRank || item.SpecialtyRank === 0) {
        return '第' + item.SpecialtyRank + '名'
      } else {
        return '-'
      }
    }
  }
}
</script>

<style scoped lang="less">
.establish-college {
  padding: 3rem 0;

  .box {

  }

  .total {
    margin: 20px 0;
    color: rgba(var(--themecolor),1);
    font-size: var(--title-font-size);
  }

  .main {
    padding: 20px;
    //margin-top: 40px;
    background-color: #fff;
    border: 1px solid var(--color-grey);

    .item {
      padding-bottom: 15px;
      margin-bottom: 10px;
      border-bottom: 1px solid var(--color-grey);
      display: flex;
      align-items: center;
      justify-content: space-between;

      .logo, .info, .rank, .assess, .hot {
        flex-shrink: 0;
      }

      .logo {
        width: 80px;
      }

      .rank, .assess, .hot {
        width: 130px;

        .name {
          margin-bottom: 5px;
          font-size: var(--text-font-size);
          color: var(--text-font-color);
        }

        .value {
          font-size: var(--text-font-size);
          color: var(--text-font-color);
        }
      }

      .info {
        width: 300px;

        .name {
          margin-bottom: 5px;
          font-size: var(--title-font-size);
          color: var(--text-font-color);
          cursor: pointer;

        }

        .category, .tag {
          font-size: var(--text-font-size);
          color: var(--text-font-color);
        }
      }

      .rank {
        width: 200px;
      }
    }
  }
}

</style>
